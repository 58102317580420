.ModalCreateCoinflip {
  width: 500px !important; }
  .ModalCreateCoinflip .ModalCreateBox {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%; }
    .ModalCreateCoinflip .ModalCreateBox .ModalContent .CoinflipCreateSelectCoins {
      display: flex;
      gap: 40px;
      min-height: 220px;
      padding: 15px;
      justify-content: center;
      align-items: center;
      margin: 20px 0; }
      .ModalCreateCoinflip .ModalCreateBox .ModalContent .CoinflipCreateSelectCoins .SelectCoin {
        background-color: #0F0F14;
        padding: 15px;
        border-radius: 4px;
        border: 1px solid transparent;
        position: relative;
        cursor: pointer;
        max-width: 120px; }
        .ModalCreateCoinflip .ModalCreateBox .ModalContent .CoinflipCreateSelectCoins .SelectCoin.selected {
          border-color: #00c73d; }
      .ModalCreateCoinflip .ModalCreateBox .ModalContent .CoinflipCreateSelectCoins p {
        margin: 15px 0 0; }
      .ModalCreateCoinflip .ModalCreateBox .ModalContent .CoinflipCreateSelectCoins i {
        position: absolute;
        top: 5px;
        right: 5px;
        color: #00c73d; }
      .ModalCreateCoinflip .ModalCreateBox .ModalContent .CoinflipCreateSelectCoins img {
        width: 100%; }
    .ModalCreateCoinflip .ModalCreateBox .ModalFooterDeposit {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between; }
      .ModalCreateCoinflip .ModalCreateBox .ModalFooterDeposit .FooterInfo {
        display: flex;
        padding: 5px 10px; }
      .ModalCreateCoinflip .ModalCreateBox .ModalFooterDeposit .FooterButtons {
        display: grid;
        justify-content: flex-end;
        grid-template-columns: auto auto;
        padding: 0 5px;
        gap: 10px; }
        .ModalCreateCoinflip .ModalCreateBox .ModalFooterDeposit .FooterButtons button {
          padding: 12px 15px;
          background-color: transparent;
          color: #f3f3ff99;
          border-radius: 2px; }
          .ModalCreateCoinflip .ModalCreateBox .ModalFooterDeposit .FooterButtons button.Accept {
            background-color: #00c73d;
            color: #fff;
            padding: 12px 25px;
            text-transform: uppercase;
            font-weight: 600; }
            .ModalCreateCoinflip .ModalCreateBox .ModalFooterDeposit .FooterButtons button.Accept:disabled {
              opacity: .5;
              cursor: default; }
            .ModalCreateCoinflip .ModalCreateBox .ModalFooterDeposit .FooterButtons button.Accept:not(:disabled) {
              box-shadow: 0 0 7px #00c73d; }
  .ModalCreateCoinflip.ModalCreateCoinflipCreated {
    width: 800px !important; }
    .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalHeader {
      justify-content: space-between; }
      .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalHeader .UserInventoryInfo {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px; }
        .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalHeader .UserInventoryInfo span {
          font-size: 0.9em;
          color: #f3f3ff99;
          padding: 3px 5px;
          white-space: nowrap; }
          .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalHeader .UserInventoryInfo span i {
            color: #ffc900;
            margin-right: 5px; }
    .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .DepositPanel {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px; }
      .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .DepositPanel span {
        margin-right: 20px; }
        .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .DepositPanel span.CancelCheck {
          display: flex;
          margin-left: auto; }
      .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .DepositPanel .DepositRefresh {
        background-color: #212127;
        color: #f3f3ff99;
        padding: 10px;
        border-radius: 4px; }
    .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .ItemsBox {
      margin: 10px 15px;
      background-color: #0F0F14;
      height: 400px;
      overflow-y: scroll;
      overflow-x: hidden;
      border-radius: 4px; }
      .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .ItemsBox .ItemsWrapper {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, auto));
        grid-auto-rows: max-content;
        gap: 15px;
        padding: 15px;
        min-height: 400px; }
        .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .ItemsBox .ItemsWrapper .InventoryLoader {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
        .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .ItemsBox .ItemsWrapper .DepositItem {
          display: flex;
          position: relative;
          background-color: #1C1D24;
          border-radius: 3px;
          cursor: pointer;
          border: 1px solid transparent;
          transition: all .1s ease; }
          .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .ItemsBox .ItemsWrapper .DepositItem img {
            max-width: calc(100% - 20px);
            margin: 10px;
            filter: drop-shadow(0 0 6px #ffffff08); }
          .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .ItemsBox .ItemsWrapper .DepositItem span {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 0.9em;
            padding: 2px 5px;
            background-color: #212127;
            border-radius: 4px 0 4px 0;
            box-shadow: 2px 2px 7px #0F0F14;
            color: #eee; }
          .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .ItemsBox .ItemsWrapper .DepositItem.selected {
            background-color: #00c73d1f;
            border-color: #00c73d; }
            .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .ItemsBox .ItemsWrapper .DepositItem.selected img {
              opacity: 0.9;
              filter: drop-shadow(0 0 10px #00c73d1f); }
            .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .ItemsBox .ItemsWrapper .DepositItem.selected i {
              position: absolute;
              top: 5px;
              right: 5px;
              color: #00c73d; }
          .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .ItemsBox .ItemsWrapper .DepositItem.disabled {
            opacity: 0.5; }
            .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .ItemsBox .ItemsWrapper .DepositItem.disabled span {
              display: none; }
            .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .ItemsBox .ItemsWrapper .DepositItem.disabled i {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 2em;
              color: #c80048; }
            .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalContent .ItemsBox .ItemsWrapper .DepositItem.disabled img {
              filter: grayscale(100%); }
    .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalFooterDeposit {
      display: flex;
      align-items: center; }
      .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalFooterDeposit .FooterInfo {
        display: flex;
        padding: 0 10px;
        flex-wrap: wrap;
        gap: 10px 20px; }
        .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalFooterDeposit .FooterInfo .DepositValue {
          display: flex;
          padding: 4px 8px;
          background-color: #0F0F14;
          border-radius: 4px;
          align-items: center; }
          .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalFooterDeposit .FooterInfo .DepositValue i {
            margin: 0 3px; }
            .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalFooterDeposit .FooterInfo .DepositValue i.good {
              color: #00c73d; }
            .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalFooterDeposit .FooterInfo .DepositValue i.bad {
              color: #c80048; }
          .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalFooterDeposit .FooterInfo .DepositValue span {
            margin: 0 3px; }
            .ModalCreateCoinflip.ModalCreateCoinflipCreated .ModalFooterDeposit .FooterInfo .DepositValue span.Value {
              color: #f3f3ff99; }
