#not-found {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 50px; }
  #not-found h1 {
    text-align: center; }
  #not-found #go-back {
    text-align: center;
    font-size: 24px; }
    #not-found #go-back a {
      color: #137ae7; }
