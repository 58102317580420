.ModalTradeOffer {
  width: 480px !important;
  display: grid;
  grid-template-rows: 40px 1fr 60px; }
  .ModalTradeOffer .ModalHeader {
    justify-content: center !important; }
  .ModalTradeOffer .ModalContent {
    min-height: 100px !important;
    padding: 15px; }
    .ModalTradeOffer .ModalContent .TradeOfferContent {
      display: grid;
      grid-template-rows: auto;
      gap: 10px; }
      .ModalTradeOffer .ModalContent .TradeOfferContent .OfferWarnings {
        display: flex; }
        .ModalTradeOffer .ModalContent .TradeOfferContent .OfferWarnings .OfferWarning {
          padding: 5px; }
          .ModalTradeOffer .ModalContent .TradeOfferContent .OfferWarnings .OfferWarning i {
            font-size: 2rem;
            color: #137ae7;
            margin: 10px; }
      .ModalTradeOffer .ModalContent .TradeOfferContent div {
        padding: 5px; }
        .ModalTradeOffer .ModalContent .TradeOfferContent div p {
          margin: 0; }
        .ModalTradeOffer .ModalContent .TradeOfferContent div span {
          margin-top: 7px;
          background-color: #07070a;
          color: #eee;
          border-radius: 4px;
          display: block;
          font-size: .9em;
          padding: 7px 30px;
          position: relative; }
          .ModalTradeOffer .ModalContent .TradeOfferContent div span i {
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translate(-50%, -50%);
            color: orange; }
            .ModalTradeOffer .ModalContent .TradeOfferContent div span i:nth-child(2) {
              left: auto;
              right: 15px;
              transform: translate(50%, -50%); }
  .ModalTradeOffer .ModalFooter {
    display: flex;
    background-color: transparent !important; }
    .ModalTradeOffer .ModalFooter a {
      flex: 1;
      text-align: center; }
    .ModalTradeOffer .ModalFooter button {
      background-color: #00c73d;
      width: 100%;
      color: #eee;
      padding: 15px;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 2px; }
