html {
  background-color: #0f0f14; }

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  font-size: 11px;
  padding: 0;
  font-family: 'Inter','Helvetica Neue',Arial,Helvetica,sans-serif !important;
  color: #f3f3ff66;
  background-color: #0f0f14;
  overflow: hidden; }
  body ::-webkit-scrollbar {
    width: 0; }
  body ::-webkit-scrollbar-track {
    width: 0;
    background-color: transparent; }
  body ::-webkit-scrollbar-thumb {
    width: 0; }
  body a {
    cursor: pointer;
    text-decoration: none;
    color: #f3f3ff66; }
  body p {
    margin: 10px 0; }
  body button {
    border: none;
    font-family: 'Inter','Helvetica Neue',Arial,Helvetica,sans-serif !important;
    font-weight: 500;
    cursor: pointer;
    transition: all .15s ease; }
    body button:focus {
      outline: none; }
    body button:hover {
      opacity: .85; }

*, :after, :before {
  box-sizing: border-box; }

.AppLoading {
  position: fixed;
  top: 0;
  left: 0;
  tom: 0;
  right: 0;
  background-color: #07070a;
  z-index: 1000000; }

.sk-chase {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both; }

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; }

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s; }

.sk-chase-dot:nth-child(2) {
  animation-delay: -1.0s; }

.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s; }

.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s; }

.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s; }

.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s; }

.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s; }

.sk-chase-dot:nth-child(2):before {
  animation-delay: -1.0s; }

.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s; }

.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s; }

.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s; }

.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s; }

@keyframes sk-chase {
  100% {
    transform: rotate(360deg); } }

@keyframes sk-chase-dot {
  80%, 100% {
    transform: rotate(360deg); } }

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); }
  100%, 0% {
    transform: scale(1); } }

.container {
  max-width: 1140px;
  padding: 50px 20px 20px;
  width: 100%;
  margin: auto; }
  .container p {
    text-align: justify; }

h1 {
  font-size: 2rem;
  min-height: 1rem; }
  h1:first-child {
    margin-top: 0; }

h2 {
  font-size: 1.71428571rem; }

h3 {
  font-size: 1.28571429rem; }

h4 {
  font-size: 1.07142857rem; }

h5 {
  font-size: 1rem; }

h1, h2, h3, h4, h5 {
  margin: calc(2rem - 0.1485714em) 0em 1rem;
  font-weight: bold;
  padding: 0; }
  h1.center, h2.center, h3.center, h4.center, h5.center {
    text-align: center; }

.notification-container {
  top: auto;
  right: auto;
  left: 0;
  bottom: 0; }

.notification {
  box-shadow: 0 0 10px #000;
  margin-top: 0;
  margin-bottom: 15px; }

.notification-leave.notification-leave-active {
  transform: translate3d(-100%, 0, 0); }

.notification-enter {
  transform: translate3d(-100%, 0, 0); }

.notification-info, .notification-success, .notification-warning, .notification-error {
  background-color: #15151b !important; }

.notification-info:before {
  color: #137ae7; }

.notification-success:before {
  color: #00c73d; }

.notification-warning:before {
  color: #ffc900; }

.notification-error:before {
  color: #c80048; }

.fa, .fab, .fad, .fal, .far, .fas {
  line-height: 1.4285em !important; }

.ui.popup {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  min-width: min-content;
  max-width: 250px;
  z-index: 2500;
  border: 1px solid transparent;
  line-height: 1.4285em;
  padding: .833em 1em;
  font-weight: 400; }
  .ui.popup::before {
    position: absolute;
    content: '';
    width: .71428571em;
    height: .71428571em;
    transform: rotate(45deg);
    z-index: 2; }

.ui.top.left.popup:before {
  bottom: -.30714286em;
  left: 1em;
  top: auto;
  right: 1em;
  margin-left: 0; }

.ui.top.right.popup:before {
  bottom: -.30714286em;
  left: auto;
  top: auto;
  right: 1em;
  margin-right: 0; }

.ui.top.center.popup:before {
  bottom: -.30714286em;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  top: auto;
  right: auto;
  margin-left: 0; }

.transition {
  animation-iteration-count: 1;
  animation-duration: .3s;
  animation-timing-function: ease;
  animation-fill-mode: both; }

.visible.transition {
  display: block !important;
  visibility: visible !important; }

.ui.animating.popup, .ui.visible.popup {
  display: block; }

.ui.top.popup {
  margin: 0 0 .71428571em; }

.ui.visible.popup {
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.ui.top.right.popup {
  transform-origin: right bottom;
  margin-right: 0; }

.text-bigger {
  font-size: 13px; }

.text-big {
  font-size: 16px; }
