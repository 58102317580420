@keyframes gameIn {
  0% {
    transform: scale(0);
    opacity: 0; }
  100% {
    transform: scale(1); } }

.Coinflip {
  width: 100%;
  max-width: 1140px;
  margin: 50px auto 20px; }
  .Coinflip .CoinflipGames {
    display: flex;
    flex-direction: column;
    color: #f3f3ff66; }
    .Coinflip .CoinflipGames .CoinflipGame {
      display: flex;
      flex-direction: row;
      background-color: #1a1c24;
      border-radius: 2px;
      text-align: center;
      position: relative;
      animation: 0.5s gameIn ease;
      margin-bottom: 2px; }
      .Coinflip .CoinflipGames .CoinflipGame:nth-child(even) {
        background-color: #1a1c24; }
      .Coinflip .CoinflipGames .CoinflipGame div {
        display: flex;
        justify-content: space-evenly;
        align-items: center; }
        .Coinflip .CoinflipGames .CoinflipGame div.CoinflipPlayers {
          display: flex;
          position: relative;
          padding: 10px; }
          .Coinflip .CoinflipGames .CoinflipGame div.CoinflipPlayers .Player {
            width: 46px;
            height: 46px;
            border-radius: 46px;
            position: relative;
            margin: auto;
            margin: 15px; }
            .Coinflip .CoinflipGames .CoinflipGame div.CoinflipPlayers .Player img {
              width: 36px;
              height: 36px;
              margin: 5px;
              border-radius: 46px; }
            .Coinflip .CoinflipGames .CoinflipGame div.CoinflipPlayers .Player.Red {
              border: 2px solid #E71359;
              box-shadow: #E71359 0 0 6px 0;
              order: 3; }
              .Coinflip .CoinflipGames .CoinflipGame div.CoinflipPlayers .Player.Red span {
                width: 30px;
                height: 30px;
                bottom: -5px;
                right: -5px;
                position: absolute;
                background-size: cover;
                background-position: center center;
                background-image: url(../../static/redcoin.svg);
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                border-radius: 20px; }
            .Coinflip .CoinflipGames .CoinflipGame div.CoinflipPlayers .Player.Blue {
              border: 2px solid #137ae7;
              box-shadow: #137ae7 0 0 6px 0;
              order: 1; }
              .Coinflip .CoinflipGames .CoinflipGame div.CoinflipPlayers .Player.Blue span {
                width: 30px;
                height: 30px;
                bottom: -5px;
                right: -5px;
                position: absolute;
                background-size: cover;
                background-position: center center;
                background-image: url(../../static/bluecoin.svg);
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                border-radius: 20px; }
          .Coinflip .CoinflipGames .CoinflipGame div.CoinflipPlayers .Anonymous {
            width: 46px;
            height: 46px;
            border-radius: 46px;
            border: 2px dashed #ffffff21;
            margin: auto;
            margin: 15px; }
            .Coinflip .CoinflipGames .CoinflipGame div.CoinflipPlayers .Anonymous.Red {
              order: 1; }
            .Coinflip .CoinflipGames .CoinflipGame div.CoinflipPlayers .Anonymous.Blue {
              order: 3; }
            .Coinflip .CoinflipGames .CoinflipGame div.CoinflipPlayers .Anonymous i {
              color: #ffffff21; }
        .Coinflip .CoinflipGames .CoinflipGame div.CoinflipItems {
          display: flex;
          padding: 0 15px;
          justify-content: flex-start;
          flex: 1;
          overflow: hidden; }
          .Coinflip .CoinflipGames .CoinflipGame div.CoinflipItems .CoinflipItem {
            width: 50px;
            height: 50px;
            border-radius: 3px;
            margin: 2px;
            padding: 5px;
            cursor: pointer; }
            .Coinflip .CoinflipGames .CoinflipGame div.CoinflipItems .CoinflipItem img {
              width: 100%; }
        .Coinflip .CoinflipGames .CoinflipGame div.CoinflipValue {
          position: relative;
          padding: 0 10px;
          min-width: 100px;
          gap: 10px;
          justify-content: center;
          flex-flow: column nowrap; }
          .Coinflip .CoinflipGames .CoinflipGame div.CoinflipValue span.Value {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #f3f3ff99;
            font-size: 1.05em; }
          .Coinflip .CoinflipGames .CoinflipGame div.CoinflipValue span.Range {
            font-size: 0.85em; }
        .Coinflip .CoinflipGames .CoinflipGame div.CoinflipOptions {
          justify-content: flex-end;
          padding: 0 15px;
          display: flex;
          min-width: 160px; }
          .Coinflip .CoinflipGames .CoinflipGame div.CoinflipOptions button {
            padding: 8px;
            margin: 0 5px;
            border-radius: 2px;
            background-color: #323541;
            color: #f3f3ff99;
            flex: 1; }
            .Coinflip .CoinflipGames .CoinflipGame div.CoinflipOptions button.Join {
              background-color: #00c73d;
              color: #eee;
              padding: 8px 15px; }
        .Coinflip .CoinflipGames .CoinflipGame div.CoinflipStatus {
          text-align: center;
          min-width: 100px;
          padding: 0 15px;
          display: flex;
          justify-content: center; }
          .Coinflip .CoinflipGames .CoinflipGame div.CoinflipStatus span {
            font-weight: 700; }
            .Coinflip .CoinflipGames .CoinflipGame div.CoinflipStatus span.Open {
              color: #00c73d; }
            .Coinflip .CoinflipGames .CoinflipGame div.CoinflipStatus span.Blue {
              width: 36px;
              height: 36px;
              background-image: url(../../static/bluecoin.svg);
              background-size: cover;
              background-repeat: no-repeat; }
            .Coinflip .CoinflipGames .CoinflipGame div.CoinflipStatus span.Red {
              width: 36px;
              height: 36px;
              background-image: url(../../static/redcoin.svg);
              background-size: cover;
              background-repeat: no-repeat; }
        .Coinflip .CoinflipGames .CoinflipGame div.CoinflipOptions .cfButton {
          margin: 0 5px; }

.CoinflipHeader {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px; }
  .CoinflipHeader .CoinflipStats {
    display: grid;
    grid-template-columns: repeat(3, auto);
    border-radius: 2px;
    background-color: #323541;
    width: fit-content; }
    .CoinflipHeader .CoinflipStats .CoinflipStat {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 8px 15px;
      color: #f3f3ff66;
      font-size: 1.15em; }
      .CoinflipHeader .CoinflipStats .CoinflipStat i {
        margin-right: 8px; }
        .CoinflipHeader .CoinflipStats .CoinflipStat i.fa-dollar-sign {
          color: #00c73d; }
        .CoinflipHeader .CoinflipStats .CoinflipStat i.fa-inbox {
          color: #ffc900; }
        .CoinflipHeader .CoinflipStats .CoinflipStat i.fa-circle-notch {
          color: #137ae7; }
  .CoinflipHeader .CoinflipHeaderButtons {
    flex: 1;
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .CoinflipHeader .CoinflipHeaderButtons button {
      margin-left: 10px;
      flex: 1;
      padding: 10px;
      border-radius: 2px;
      font-weight: 600;
      text-transform: uppercase;
      background-color: #1a1c24;
      color: #f3f3ff99; }
      .CoinflipHeader .CoinflipHeaderButtons button.Create {
        background-color: #E71359;
        color: #eee; }

@media (max-width: 750px) {
  .CoinflipGame {
    flex-wrap: wrap;
    gap: 15px 0;
    margin-bottom: 15px !important; }
    .CoinflipGame .CoinflipPlayers {
      width: 100%; }
    .CoinflipGame .CoinflipItems {
      flex: none !important;
      justify-content: center !important;
      width: 100%; }
    .CoinflipGame .CoinflipValue, .CoinflipGame .CoinflipStatus {
      width: 50%; }
    .CoinflipGame .CoinflipOptions {
      width: 100%;
      margin-bottom: 15px; } }
