.AdminHeader {
  flex: 1;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  padding: 15px 0;
  display: flex; }
  .AdminHeader .WithdrawAll {
    padding: 8px 15px;
    border-radius: 2px;
    background-color: #137ae7;
    font-weight: 600;
    color: #fff; }
  .AdminHeader .AdminInput {
    flex: 1; }
    .AdminHeader .AdminInput input {
      background-color: #07070a;
      border-radius: 3px;
      border: 1px solid transparent;
      padding: 8px 15px;
      color: #f3f3ff99;
      width: 100%; }
      .AdminHeader .AdminInput input:focus {
        outline: none;
        border-color: #6435c9; }

.AdminRakeRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 5px 0;
  padding: 8px 20px;
  text-align: center;
  border-radius: 2px;
  background-color: #1a1c24;
  flex-wrap: wrap; }
  .AdminRakeRow div {
    flex: 1; }
  .AdminRakeRow.withdrawed {
    opacity: .8; }
  .AdminRakeRow .AdminRakeItemCell img {
    max-width: 40px; }
  .AdminRakeRow .AdminWithdraw {
    padding: 5px 10px;
    border-radius: 2px;
    background-color: #00c73d;
    color: #fff; }
    .AdminRakeRow .AdminWithdraw.Resend {
      background-color: #137ae7; }
  .AdminRakeRow .AdminRakeItemWithdrawCell i.fas {
    color: #00c73d; }
  .AdminRakeRow.Trade {
    padding: 20px; }
    .AdminRakeRow.Trade div {
      padding: 5px; }

.AdminDropdown {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  gap: 20px; }
  .AdminDropdown:focus {
    outline: none; }
  .AdminDropdown .text {
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 2px;
    background-color: #323541; }
  .AdminDropdown .menu {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap; }
    .AdminDropdown .menu.visible.transition {
      display: flex !important; }
    .AdminDropdown .menu .item {
      cursor: pointer;
      padding: 5px 10px;
      transition: all .15s ease;
      margin: auto; }
      .AdminDropdown .menu .item:hover {
        opacity: .75; }

.OfferStatus {
  padding: 4px 6px;
  color: #fff;
  border-radius: 2px;
  background-color: #323541;
  font-size: .85em;
  font-weight: 600; }
  .OfferStatus.True {
    background-color: #00c73d; }
  .OfferStatus.False {
    background-color: #E71359; }

.AdminTradesItems {
  width: 100%;
  display: flex;
  flex: none !important;
  background-color: #323541;
  border-radius: 2px;
  padding: 10px;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center; }
  .AdminTradesItems img {
    max-width: 36px; }

.AdminWithdrawDateStatus button {
  border-radius: 2px;
  border: none;
  background-color: #137ae7;
  color: #fff;
  font-size: .9em;
  font-weight: 600; }

.AdminWithdrawDateStatus i.fas {
  color: #00c73d; }

@media only screen and (max-width: 500px) {
  .AdminRakeRow div {
    min-width: 100%; } }
