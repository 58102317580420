.Landing {
  z-index: 4;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  text-align: center;
  color: #b5babb;
  background-color: rgba(38, 38, 44, 0.95); }
  .Landing img {
    height: 120px;
    margin-top: 50px;
    padding-bottom: 0px;
    margin-bottom: 0px; }
  .Landing h1 {
    margin-top: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 15px; }
  .Landing hr {
    width: 70%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(189, 181, 181, 0), rgba(189, 181, 181, 0.55), rgba(189, 181, 181, 0)); }

.Landing__Stats {
  width: 50%;
  margin: 0 auto; }
  .Landing__Stats .Landing__Stats-Selector {
    font-size: 10px;
    color: #b9193f;
    text-decoration: underline; }
    .Landing__Stats .Landing__Stats-Selector span {
      margin: 0 2px;
      cursor: pointer; }
      .Landing__Stats .Landing__Stats-Selector span:last-child:after {
        content: ''; }
      .Landing__Stats .Landing__Stats-Selector span:after {
        content: '|';
        width: 1px;
        margin-left: 2px;
        text-decoration: none;
        cursor: default;
        display: inline-block; }

.Landng__EnterButton {
  height: 50px;
  width: 280px;
  background: #ac0029;
  cursor: pointer;
  color: #eee;
  margin: 0 auto;
  position: relative;
  line-height: 50px;
  text-align: center;
  font-size: 22px;
  outline: none;
  border: 0;
  font-family: "Roboto";
  padding: 10px 80px;
  padding-left: 15px;
  margin: 50px auto;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  border-radius: 5px;
  text-transform: uppercase;
  transition: background 0.3s ease;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: initial; }
  .Landng__EnterButton:hover {
    background: #c62b50;
    text-decoration: none;
    color: #eee; }
  .Landng__EnterButton p {
    text-transform: uppercase;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    line-height: 50px; }

.Landing__TOS {
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 12px; }
  .Landing__TOS a {
    text-decoration: underline;
    color: inherit;
    transition: 0.3s ease; }
    .Landing__TOS a:hover {
      color: #989b9c; }
