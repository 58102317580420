.ReferralCodeShow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  border-radius: 2px;
  padding: 20px;
  background-color: #1a1c24;
  margin: 15px 0; }

.ReferralCodeInput {
  flex: 1;
  padding: 8px 15px;
  background-color: #1a1c24;
  color: #00c73d;
  border: none;
  border-radius: 2px;
  min-width: 120px;
  width: 100%; }
  .ReferralCodeInput:focus {
    outline: none; }

.CopyButton {
  padding: 5px;
  background-color: transparent;
  color: #ffc900; }

.ReferralInfo {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  gap: 40px;
  align-items: stretch;
  margin: 15px 0; }

.ReferralStats {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex: 2; }
  .ReferralStats .ReferralStat {
    background-color: #1a1c24;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    flex: 1;
    padding: 10px 15px; }
    .ReferralStats .ReferralStat .Value {
      font-size: 1.2rem;
      color: #eee; }

.ReferralAvailable {
  flex: 1;
  display: flex;
  background-color: rgba(0, 199, 61, 0.35);
  border: 1px solid #00c73d;
  align-items: center;
  border-radius: 2px;
  justify-content: space-between;
  padding: 15px;
  flex-wrap: wrap; }
  .ReferralAvailable .Value {
    font-size: 1.2rem;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 600; }
  .ReferralAvailable button {
    background-color: #00c73d;
    border: none;
    border-radius: 2px;
    padding: 8px 15px;
    color: #fff;
    text-transform: uppercase; }

.ReferralsProgress {
  border-radius: 2px;
  padding: 20px;
  background-color: #1a1c24;
  display: flex;
  gap: 20px;
  font-weight: 600; }
  .ReferralsProgress .LevelProgress {
    flex: 1;
    background-color: #1a1c24;
    background-image: linear-gradient(135deg, #00000026 25%, #0000 25%, #0000 50%, #00000026 50%, #00000026 75%, #0000 75%, #0000);
    border-radius: inherit;
    height: 14px;
    display: flex;
    background-size: 5px 5px;
    animation: backgroundAnimation 0.5s linear infinite; }
    .ReferralsProgress .LevelProgress .LevelProgressInner {
      border-radius: inherit;
      width: 0;
      background-image: linear-gradient(135deg, #00000026 25%, #0000 25%, #0000 50%, #00000026 50%, #00000026 75%, #0000 75%, #0000);
      background-color: #f3f3ff99;
      background-size: 1em 1em;
      animation: referralsIn 0.5s ease-in-out; }
      .ReferralsProgress .LevelProgress .LevelProgressInner.Level-1 {
        background-color: #e71359; }
      .ReferralsProgress .LevelProgress .LevelProgressInner.Level-2 {
        background-color: #137ae7; }
      .ReferralsProgress .LevelProgress .LevelProgressInner.Level-3 {
        background-color: #7013e7; }
      .ReferralsProgress .LevelProgress .LevelProgressInner.Level-4 {
        background-color: #13e773; }
      .ReferralsProgress .LevelProgress .LevelProgressInner.Level-5 {
        background-color: #e7d413; }
      .ReferralsProgress .LevelProgress .LevelProgressInner.Level-6 {
        background-color: #e75f13; }
      .ReferralsProgress .LevelProgress .LevelProgressInner.Level-7 {
        background-color: #e713e4; }
      .ReferralsProgress .LevelProgress .LevelProgressInner.Level-8 {
        background-color: #139be7; }
      .ReferralsProgress .LevelProgress .LevelProgressInner.Level-9 {
        background-color: #afea09; }
      .ReferralsProgress .LevelProgress .LevelProgressInner.Level-10 {
        background-color: #a44cfc; }

.ReferralUserInfo {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  margin: 15px 0;
  justify-content: space-between;
  align-items: center; }
  .ReferralUserInfo .ReferralUserInfoBlock {
    display: flex;
    gap: 20px;
    padding: 20px;
    justify-content: space-between;
    background-color: #1a1c24;
    align-items: center;
    flex: 1;
    border-radius: 2px; }
    .ReferralUserInfo .ReferralUserInfoBlock .UserReffLevel {
      font-size: 1.2em;
      color: #eee;
      font-weight: 600; }
    .ReferralUserInfo .ReferralUserInfoBlock .Value {
      color: #137ae7; }

i.fas.Level-1 {
  color: #e71359; }

i.fas.Level-2 {
  color: #137ae7; }

i.fas.Level-3 {
  color: #7013e7; }

i.fas.Level-4 {
  color: #13e773; }

i.fas.Level-5 {
  color: #e7d413; }

i.fas.Level-6 {
  color: #e75f13; }

i.fas.Level-7 {
  color: #e713e4; }

i.fas.Level-8 {
  color: #139be7; }

i.fas.Level-9 {
  color: #afea09; }

i.fas.Level-10 {
  color: #a44cfc; }

.ReferralWithdrawTable {
  display: flex;
  flex-direction: column; }
  .ReferralWithdrawTable .ReferralWithdrawItem {
    background-color: #1a1c24;
    display: flex;
    padding: 10px 20px;
    gap: 20px;
    border-radius: 2px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    .ReferralWithdrawTable .ReferralWithdrawItem div {
      flex: 1;
      text-align: center; }
      .ReferralWithdrawTable .ReferralWithdrawItem div.WithdrawId {
        flex: 2; }
      .ReferralWithdrawTable .ReferralWithdrawItem div.WithdrawStatus .error {
        color: #e71359; }
      .ReferralWithdrawTable .ReferralWithdrawItem div.WithdrawStatus .success {
        color: #00c73d; }

.LevelsBox {
  display: flex;
  flex-direction: column;
  gap: 10px; }
  .LevelsBox .LevelItem {
    display: flex;
    direction: row;
    flex-wrap: wrap;
    gap: 20px;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    background-color: #1a1c24;
    border-radius: 2px;
    padding: 15px 20px; }
    .LevelsBox .LevelItem.Head {
      color: #eee; }
    .LevelsBox .LevelItem div {
      flex: 1; }
      .LevelsBox .LevelItem div.Extended {
        flex: 2; }

@keyframes referralsIn {
  0% {
    width: 0; } }

@media (max-width: 550px) {
  .WithdrawId {
    display: none; }
  .ReferralInfo {
    display: block; }
  .ReferralAvailable {
    margin: 15px 0; }
  .ReferralCodeShow {
    flex-direction: column; }
  .ReferralCodeInput {
    text-align: center; } }
