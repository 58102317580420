.TradeLinkModal {
  width: 800px !important; }
  .TradeLinkModal .ModalFooter {
    display: flex;
    justify-content: flex-end !important; }
    .TradeLinkModal .ModalFooter button {
      padding: 10px 15px;
      border-radius: 2px;
      font-weight: 600;
      margin: 0 0 0 20px;
      text-transform: uppercase;
      background-color: transparent;
      color: #f3f3ff99; }
      .TradeLinkModal .ModalFooter button.Verify {
        background-color: #00c73d;
        color: #eee; }

.User {
  display: flex;
  flex-flow: column nowrap;
  width: auto !important;
  padding: 20px !important; }
  .User .UserAvatar {
    background-color: #07070a;
    position: relative;
    display: flex;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    transition: padding 0.15s ease;
    padding: 10px;
    margin: 0 auto 20px; }
    .User .UserAvatar .UserLevel {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      padding: 5px 10px;
      border-radius: 3px;
      background-color: #07070a;
      color: #f3f3ff99;
      font-weight: 600; }
    .User .UserAvatar .ProgressRing {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      .User .UserAvatar .ProgressRing .ProgressRingCircle {
        transform: rotate(90deg);
        transform-origin: 50% 50%;
        animation: levelCircleAnimation 1.5s ease; }
    .User .UserAvatar img {
      border-radius: 50%;
      max-width: calc(100% - 10px);
      max-height: calc(100% - 10px);
      margin: 10px; }
  .User .UserName {
    font-size: 1.5em;
    color: #f3f3ff99;
    padding: 5px;
    flex: 1;
    display: flex;
    justify-content: center; }
  .User .UserId, .User .UserDate {
    padding: 5px;
    flex: 1;
    display: flex;
    justify-content: center; }
  .User .UserStats {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px 0;
    justify-content: center;
    align-items: center; }
    .User .UserStats .UserStat {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      min-width: 200px;
      border-radius: 3px;
      background-color: #1c1d24; }
      .User .UserStats .UserStat.Balance.over span {
        color: #00c73d; }
      .User .UserStats .UserStat.Balance.under span {
        color: #e71359; }
      .User .UserStats .UserStat span {
        font-size: 1.35em;
        color: #f3f3ff99;
        padding: 15px 5px;
        font-weight: 600; }
      .User .UserStats .UserStat p {
        width: 100%;
        text-align: center;
        padding: 5px;
        background-color: #00000040;
        border-radius: 0 0 3px 3px;
        font-weight: 600; }
  .User .TradeUrl {
    border-radius: 3px;
    display: flex;
    margin: 20px 0;
    flex-wrap: wrap; }
    .User .TradeUrl .TradeUrlInput {
      flex: 1;
      border: none;
      color: #f3f3ff99;
      background-color: #07070a;
      border-radius: 3px 0 0 3px; }
      .User .TradeUrl .TradeUrlInput input {
        background-color: transparent;
        border: none;
        color: #f3f3ff99;
        padding: 15px;
        width: 100%; }
        .User .TradeUrl .TradeUrlInput input::placeholder {
          color: #f3f3ff66; }
        .User .TradeUrl .TradeUrlInput input:active, .User .TradeUrl .TradeUrlInput input:focus {
          border: none;
          outline: none; }
    .User .TradeUrl button {
      background-color: #137ae7;
      padding: 5px 15px;
      color: #eee;
      border-radius: 0 3px 3px 0; }
    .User .TradeUrl p {
      width: 100%;
      padding: 5px 15px; }

@keyframes levelCircleAnimation {
  0% {
    stroke-dashoffset: 716.2831250184728; } }
