nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
  background-color: #1a1c24;
  width: 250px;
  display: grid !important;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr 70px;
  transition: left .25s ease; }
  nav .menuToggle {
    position: absolute;
    right: -40px;
    bottom: -40px; }
  nav .OnUserPage .Profile {
    border-color: #E71359; }
  nav .Profile {
    display: grid;
    grid-template-columns: 60px 1fr 40px;
    grid-template-rows: 80px;
    align-items: center;
    transition: background .15s ease-out;
    cursor: pointer;
    border-left: 2px solid transparent;
    color: #f3f3ff99; }
    nav .Profile:hover {
      background-color: #0004; }
      nav .Profile:hover i {
        color: #00c73d; }
    nav .Profile img {
      max-width: 30px;
      border-radius: 2px;
      margin: 20px; }
    nav .Profile .Nick {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 1.05em; }
  nav .Login a {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #f3f3ff99;
    font-size: 1.05em;
    transition: all .15s ease-out; }
    nav .Login a i {
      margin-right: 10px; }
    nav .Login a:hover {
      background-color: #0004;
      color: #00c73d; }
  nav .Menu {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    overflow: auto; }
    nav .Menu .Divider {
      width: 100%;
      padding: 10px 30px;
      font-size: 11px;
      margin-top: 15px; }
    nav .Menu .Item {
      width: 100%;
      height: fit-content;
      padding: 15px 30px;
      text-transform: uppercase;
      display: flex;
      justify-content: flex-end;
      letter-spacing: 1px;
      color: #f3f3ff99;
      border-left: 2px solid transparent; }
      nav .Menu .Item i {
        margin-right: 0; }
      nav .Menu .Item span {
        margin-left: auto; }
        nav .Menu .Item span.Value {
          margin: 0 5px;
          font-size: .95em;
          color: #f3f3ff66;
          display: flex;
          align-items: center;
          justify-content: center; }
      nav .Menu .Item:hover {
        background-color: #0004; }
        nav .Menu .Item:hover i {
          color: #FFFFFF; }
      nav .Menu .Item.Second {
        color: #f3f3ff66; }
      nav .Menu .Item.Logout {
        margin-top: 20px;
        font-size: .9em; }
        nav .Menu .Item.Logout:hover {
          color: #E71359; }
          nav .Menu .Item.Logout:hover i {
            color: #E71359; }
    nav .Menu .Active .Item {
      border-color: #00c73d; }
      nav .Menu .Active .Item i {
        color: #00c73d; }
      nav .Menu .Active .Item.Second {
        border-color: #6435c9; }
        nav .Menu .Active .Item.Second i {
          color: #6435c9; }
  nav .Socials {
    display: flex;
    justify-content: center;
    align-items: center; }
    nav .Socials a {
      color: #f3f3ff66;
      padding: 15px;
      margin: 5px;
      transition: all .15s ease; }
      nav .Socials a:hover i.fa-twitter {
        color: #1da1f2; }
      nav .Socials a:hover i.fa-discord {
        color: #7289da; }

@media (max-width: 1280px) {
  nav {
    left: -280px; }
    nav.open {
      left: 0; } }
