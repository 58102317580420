.HistoryMenu {
  display: flex;
  flex-wrap: wrap;
  border-radius: 2px; }
  .HistoryMenu .item {
    padding: 15px;
    flex: 1;
    font-size: 1.25em;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    color: #f3f3ff99; }
    .HistoryMenu .item.active {
      background-color: #1a1c24; }

.HistoryPagination {
  display: flex;
  width: min-content;
  height: min-content;
  margin: 20px auto; }
  .HistoryPagination .item {
    padding: 5px 10px;
    background-color: #1a1c24;
    transition: all .15s ease; }
    .HistoryPagination .item:first-child {
      border-radius: 3px 0 0 3px; }
    .HistoryPagination .item:last-child {
      border-radius: 0 3px 3px 0; }
    .HistoryPagination .item:hover {
      background-color: #1c1d24; }
    .HistoryPagination .item.active {
      font-weight: 500;
      color: #f3f3ff99; }

.JackpotHistoryRow {
  padding: 15px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  background-color: #1a1c24;
  margin: 20px 0;
  border-radius: 3px;
  position: relative;
  align-items: center; }
  .JackpotHistoryRow:nth-child(odd) {
    background-color: #1a1c24; }
  .JackpotHistoryRow div {
    display: flex;
    gap: 10px;
    align-items: center; }
  .JackpotHistoryRow .Avatar {
    max-width: 34px;
    height: 34px;
    border-radius: 3px;
    margin: 0 5px; }
  .JackpotHistoryRow .Items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    background-color: #16181f;
    border-radius: 3px; }
    .JackpotHistoryRow .Items img {
      max-width: 40px;
      margin: 5px;
      cursor: pointer; }
  .JackpotHistoryRow .AbsoluteInfo {
    position: absolute;
    padding: 5px 10px;
    background-color: #0b0b0f;
    border-radius: 3px;
    top: 0;
    right: 0;
    font-weight: 500;
    color: #f3f3ff99; }
  .JackpotHistoryRow .Info {
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start; }
    .JackpotHistoryRow .Info p {
      font-size: 11px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
