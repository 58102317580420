.CoinflipTradeoffersModal {
  width: 700px !important; }
  .CoinflipTradeoffersModal .CoinflipTradeOffersContent {
    overflow-y: scroll;
    max-height: 500px;
    padding: 10px 15px; }
    .CoinflipTradeoffersModal .CoinflipTradeOffersContent .OfferRow {
      background-color: #bcbcff08;
      display: grid;
      grid-template-columns: 50px 1fr 160px 60px 40px 40px;
      gap: 10px;
      padding: 10px 15px;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      color: #f3f3ff66; }
      .CoinflipTradeoffersModal .CoinflipTradeOffersContent .OfferRow.Header {
        background-color: transparent; }
      .CoinflipTradeoffersModal .CoinflipTradeOffersContent .OfferRow .OfferId {
        color: #f3f3ff99;
        font-size: 7px; }
      .CoinflipTradeoffersModal .CoinflipTradeOffersContent .OfferRow .BotId img {
        margin-top: -12px; }
      .CoinflipTradeoffersModal .CoinflipTradeOffersContent .OfferRow .Status span {
        color: #FFC900; }
        .CoinflipTradeoffersModal .CoinflipTradeOffersContent .OfferRow .Status span.Success {
          color: #00c73d; }
        .CoinflipTradeoffersModal .CoinflipTradeOffersContent .OfferRow .Status span.Fail {
          color: #c80048; }
      .CoinflipTradeoffersModal .CoinflipTradeOffersContent .OfferRow .Link a {
        color: #137ae7; }
      .CoinflipTradeoffersModal .CoinflipTradeOffersContent .OfferRow .Cancel a {
        color: gold; }
        .CoinflipTradeoffersModal .CoinflipTradeOffersContent .OfferRow .Cancel a:not(.Allowed) {
          opacity: 0;
          color: green;
          cursor: not-allowed; }
  .CoinflipTradeoffersModal .ModalOffersFooter {
    display: flex;
    align-items: center;
    z-index: 10;
    box-shadow: -5px 0 10px #0B0B0F; }
    .CoinflipTradeoffersModal .ModalOffersFooter .FooterDescrition {
      display: flex;
      padding: 10px;
      justify-content: flex-start;
      flex-wrap: wrap; }
    .CoinflipTradeoffersModal .ModalOffersFooter .FooterButtons {
      display: flex;
      justify-content: space-evenly; }
      .CoinflipTradeoffersModal .ModalOffersFooter .FooterButtons button {
        padding: 10px;
        border-radius: 2px;
        font-weight: 600;
        text-transform: uppercase;
        background-color: transparent;
        color: #f3f3ff99; }

@media (max-width: 650px) {
  .OfferRow {
    grid-template-columns: repeat(6, auto) !important;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis; }
    .OfferRow div {
      padding: 10px;
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis; } }
