@keyframes iconPulse {
  0% {
    filter: drop-shadow(0 0 0 #137ae7); }
  50% {
    filter: drop-shadow(0 0 2px #137ae7); }
  100% {
    filter: drop-shadow(0 0 0 #137ae7); } }

.sideBar {
  width: 250px;
  transition: 0.3s ease;
  height: auto;
  display: grid;
  z-index: 2000;
  grid-template-rows: 80px auto 70px;
  gap: 10px;
  background-color: #1a1c24;
  transition: right .25s ease-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0; }
  .sideBar .onlineUsersBox {
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .15s ease-out; }
    .sideBar .onlineUsersBox:hover {
      background-color: #0004; }
    .sideBar .onlineUsersBox .onlineUsers {
      color: #f3f3ff99; }
      .sideBar .onlineUsersBox .onlineUsers i {
        margin-right: 10px;
        color: #137ae7;
        animation: iconPulse 2s ease;
        animation-iteration-count: infinite; }
  .sideBar .Chat__Box {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    position: relative; }
  .sideBar .ChatInput {
    height: 100%;
    border-top: 1px solid #323541; }
    .sideBar .ChatInput .ChatAllowed {
      display: flex;
      position: relative;
      height: 100%; }
      .sideBar .ChatInput .ChatAllowed textarea {
        width: 100%;
        height: 40px;
        border: none;
        background-color: transparent;
        padding: 10px 100px 10px 15px;
        margin: 15px 0;
        line-height: 20px;
        resize: none;
        color: #f3f3ff99;
        font-family: "Inter"; }
        .sideBar .ChatInput .ChatAllowed textarea::placeholder {
          color: #f3f3ff66; }
        .sideBar .ChatInput .ChatAllowed textarea:focus {
          outline: none !important;
          box-shadow: none; }
      .sideBar .ChatInput .ChatAllowed .ChatInputButtons {
        width: 80px;
        height: 70px;
        position: absolute;
        top: 0;
        right: 15px;
        display: flex; }
        .sideBar .ChatInput .ChatAllowed .ChatInputButtons button {
          width: 40px;
          display: flex;
          height: 70px;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          text-decoration: none;
          cursor: pointer;
          text-shadow: none !important;
          border: none !important;
          box-shadow: none !important;
          transition: all 0.25s;
          color: #f3f3ff99; }
          .sideBar .ChatInput .ChatAllowed .ChatInputButtons button:focus {
            border: none !important;
            text-shadow: none !important;
            box-shadow: none !important;
            outline: none !important; }
          .sideBar .ChatInput .ChatAllowed .ChatInputButtons button:hover i.fa-smile {
            color: #ffc900;
            filter: drop-shadow(0 0 2px #ffc900); }
          .sideBar .ChatInput .ChatAllowed .ChatInputButtons button:hover i.fa-paper-plane {
            color: #00c73d;
            filter: drop-shadow(0 0 2px #00c73d); }
    .sideBar .ChatInput .ChatAnon {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      color: #f3f3ff66; }

.chatPopup {
  background-color: #1f2129 !important;
  border-color: transparent !important;
  border-radius: 2px !important;
  box-shadow: none !important;
  color: #f3f3ff99 !important;
  backdrop-filter: blur(4px); }
  .chatPopup::before {
    background-color: #1f2129 !important;
    box-shadow: 1px 1px 0 0 transparent !important;
    backdrop-filter: blur(4px); }
  .chatPopup img {
    max-height: 20px;
    max-width: 100%;
    margin: 10px;
    cursor: pointer;
    transition: all .15s ease; }

@media (max-width: 1280px) {
  .sideBar {
    right: -280px;
    grid-template-rows: 40px auto 70px; }
    .sideBar.open {
      right: 0; }
      .sideBar.open .sideBarToggle {
        left: auto;
        right: 40px; } }
