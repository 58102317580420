.ReactModalPortal > div {
  opacity: 0; }

.ReactModalPortal .ReactModal__Overlay {
  z-index: 4;
  transition: opacity 200ms ease-in-out; }
  .ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1; }
  .ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0; }

.Modal__Overlay {
  background-color: #0009;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  overflow-y: scroll; }

.Modal {
  width: 100%;
  height: auto;
  border-radius: 2px;
  background-color: #0b0b0f;
  box-shadow: 0 0 10px #0000008a;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-bottom: 50px;
  overflow: hidden;
  outline: none;
  color: #f3f3ff66;
  max-width: calc(100vw - 40px); }
  .Modal a {
    color: #f3f3ff99; }
    .Modal a:hover {
      color: #f3f3ff66; }
  .Modal .ModalHeader {
    background-color: #0b0b0f;
    color: #f3f3ff99;
    display: flex;
    align-items: center;
    padding: 15px;
    gap: 10px;
    font-size: 14px; }
    .Modal .ModalHeader .Title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .Modal .ModalContent {
    background-color: transparent;
    height: auto;
    position: relative;
    min-height: 260px; }
  .Modal .ModalFooter {
    background-color: #15151b;
    border: none;
    padding: 10px;
    text-align: left; }
    .Modal .ModalFooter p {
      color: #f3f3ff66;
      width: 100%;
      margin: 0; }

.checkbox {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  margin: auto 7px;
  clear: both;
  cursor: pointer; }
  .checkbox input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px; }
    .checkbox input:checked ~ .checkbox-check {
      background-color: #7289da;
      border-color: #050507; }
  .checkbox .checkbox-check {
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    border-radius: 10px;
    border: 2px solid #1c1c20;
    background-color: #050507; }

@media (max-width: 800px) {
  .Modal {
    top: 10vh; } }
