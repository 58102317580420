.ModalVerifyCoinflip {
  height: auto;
  width: 440px !important;
  display: grid;
  grid-template-rows: 40px 1fr 54px;
  color: #f3f3ff99; }
  .ModalVerifyCoinflip .VerifyDescription {
    padding: 15px;
    text-align: justify; }
  .ModalVerifyCoinflip .VerifyInputs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    gap: 20px;
    margin: 20px 0; }
    .ModalVerifyCoinflip .VerifyInputs .VerifyInputBox {
      display: flex;
      width: calc(100% - 30px);
      height: 40px; }
      .ModalVerifyCoinflip .VerifyInputs .VerifyInputBox span {
        background-color: #15151b;
        color: #f3f3ff99;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        border-radius: 4px 0 0 4px;
        min-width: 55px; }
      .ModalVerifyCoinflip .VerifyInputs .VerifyInputBox input {
        flex: 1;
        height: 100%;
        background-color: #050507;
        color: #f3f3ff66;
        border: none;
        border-radius: 0 4px 4px 0;
        padding: 0 15px; }
        .ModalVerifyCoinflip .VerifyInputs .VerifyInputBox input:active, .ModalVerifyCoinflip .VerifyInputs .VerifyInputBox input:focus {
          border: none;
          outline: none; }
  .ModalVerifyCoinflip .ModalFooter {
    display: flex;
    justify-content: flex-end; }
    .ModalVerifyCoinflip .ModalFooter button {
      padding: 10px 15px;
      border-radius: 2px;
      font-weight: 600;
      margin: 0 0 0 20px;
      text-transform: uppercase;
      background-color: transparent;
      color: #f3f3ff99; }
      .ModalVerifyCoinflip .ModalFooter button.Verify {
        background-color: #00c73d;
        color: #eee; }
