@keyframes jumpIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.Message {
  padding: 10px 0;
  position: relative;
  animation: jumpIn .25s ease-in; }
  .Message .MessageContent {
    color: silver;
    line-height: 24px;
    padding: 10px 15px 5px 45px;
    vertical-align: middle;
    max-height: 170px;
    overflow: hidden;
    word-break: break-word; }
    .Message .MessageContent .Emote {
      height: 24px;
      margin: 0 2px;
      transform: translateY(6px); }
  .Message .MessageHeader {
    display: flex;
    height: 20px;
    font-size: 11px;
    align-items: center;
    color: #f3f3ffcc;
    font-weight: 600; }
    .Message .MessageHeader i {
      color: #b1d632; }
    .Message .MessageHeader a {
      color: inherit;
      text-decoration: none; }
      .Message .MessageHeader a:hover {
        color: inherit;
        text-decoration: none; }
      .Message .MessageHeader a:focus {
        text-decoration: none; }
      .Message .MessageHeader a:visited {
        color: inherit;
        text-decoration: none; }
    .Message .MessageHeader .MessageHeaderLevel {
      margin: auto 5px auto 5px;
      padding: 2px 5px;
      background: linear-gradient(#222831, #393e46);
      font-weight: bold;
      font-size: 10px;
      color: #eee;
      letter-spacing: 1px;
      border-radius: 2px; }
    .Message .MessageHeader img {
      height: 20px;
      border-radius: 20px;
      margin: auto 5px auto 15px; }
  .Message.mod .MessageHeader .MessageHeaderLevel {
    background-color: transparent;
    color: #137ae7;
    text-shadow: #137ae7 0 0 6px; }
  .Message.admin .MessageHeader .MessageHeaderLevel {
    background-color: transparent;
    color: #E71359;
    text-shadow: #E71359 0 0 6px; }
  .Message.developer .MessageHeader .MessageHeaderLevel {
    background-color: transparent;
    color: #6435c9;
    text-shadow: #6435c9 0 0 6px; }
  .Message.bot:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    left: 0;
    top: 0;
    background-color: #137ae7; }
  .Message.bot .MessageHeader {
    display: none; }
    .Message.bot .MessageHeader img {
      display: none; }
  .Message.bot .MessageContent {
    padding: 5px 15px;
    color: #f3f3ff99; }

.userActions {
  background-color: #07070a !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: #f3f3ff99 !important; }
  .userActions::before {
    background-color: #07070a !important;
    box-shadow: 1px 1px 0 0 #07070a !important;
    border: none; }
  .userActions button {
    display: flex;
    justify-content: center;
    padding: 10px;
    background-color: #15151b;
    font-size: .9em;
    color: #f3f3ff99;
    width: 100%; }
