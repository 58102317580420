.ModalCoinflipWatch {
  width: 800px !important; }
  .ModalCoinflipWatch .ModalContent .CoinflipBody {
    display: flex;
    position: relative;
    padding-bottom: 40px;
    min-height: 660px; }
    .ModalCoinflipWatch .ModalContent .CoinflipBody .Divider {
      width: 1px;
      background-color: #1c1d24;
      position: absolute;
      height: calc(100% - 310px);
      top: 260px;
      left: 50%;
      transform: translateX(-50%); }
    .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer {
      flex: 1;
      height: auto;
      width: 50%; }
      .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUser {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        padding-bottom: 10px;
        justify-content: center;
        min-height: 220px; }
        .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUser img {
          width: 104px;
          height: 104px;
          border: 4px solid;
          border-radius: 100px;
          margin: 0 auto; }
        .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUser .UserName {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #eee;
          margin: 5px; }
          .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUser .UserName.Waiting {
            color: #f3f3ff66; }
          .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUser .UserName .BotIndicator {
            padding: 2px 8px;
            margin-right: 5px;
            border-radius: 100px; }
            .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUser .UserName .BotIndicator.Red {
              background: #c80048; }
            .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUser .UserName .BotIndicator.Black {
              background: #137ae7; }
        .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUser .NoPlayer {
          width: 104px;
          height: 104px;
          border: 4px dashed #ffffff21;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto; }
          .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUser .NoPlayer i {
            color: #ffffff21; }
      .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUserPanel {
        display: flex;
        flex-direction: column;
        padding: 0 10px; }
        .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUserPanel .UserPanelInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 15px;
          background-color: #15151b;
          border-radius: 2px; }
          .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUserPanel .UserPanelInfo .Value {
            color: #f3f3ff99;
            font-size: 1.15em;
            font-weight: 600; }
        .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUserPanel .UserPanelItems {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding: 10px 5px; }
          .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUserPanel .UserPanelItems .Item {
            display: block;
            background-color: #15151b;
            position: relative;
            border-radius: 2px;
            margin: 2px;
            width: 80px; }
            .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUserPanel .UserPanelItems .Item img {
              max-width: calc(100% - 10px);
              margin: 5px; }
            .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUserPanel .UserPanelItems .Item div {
              display: flex;
              align-items: center;
              justify-content: center; }
              .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUserPanel .UserPanelItems .Item div.ItemImg {
                border-radius: 4px 0 0 4px; }
              .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer .CoinflipUserPanel .UserPanelItems .Item div.ItemPrice {
                border-radius: 0 4px 4px 0;
                color: #f3f3ff99;
                padding: 0 5px 5px; }
      .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer.Red {
        order: 2; }
        .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer.Red .CoinflipUser img {
          border-color: #c80048; }
      .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer.Black {
        order: 1; }
        .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer.Black .UserPanelInfo .Chances {
          order: 1; }
        .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipPlayer.Black .CoinflipUser img {
          border-color: #137ae7; }
    .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipStatus {
      position: absolute;
      top: 0;
      width: 220px;
      height: 220px;
      left: 50%;
      transform: translateX(-50%);
      transition: transform .15s ease; }
      .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipStatus .CounterBox {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipStatus span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipStatus .Flipper {
        height: 200px;
        background-color: #000;
        border-radius: 100px;
        position: relative;
        animation: fadeIn .15s ease-in;
        animation-fill-mode: forwards;
        width: 200px;
        margin: 10px; }
        .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipStatus .Flipper .CoinFlip {
          width: 200px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 200px;
          background-position: top left;
          background-size: cover;
          animation: CoinflipRoll 4s steps(199);
          animation-delay: .5s;
          animation-fill-mode: forwards;
          background-repeat: no-repeat; }
          .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipStatus .Flipper .CoinFlip.Red {
            background-image: url(../../../static/roll-red-2.png); }
          .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipStatus .Flipper .CoinFlip.Black {
            background-image: url(../../../static/roll-blue-2.png); }
          .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipStatus .Flipper .CoinFlip.Red-2 {
            background-image: url(../../../static/roll-red.png); }
          .ModalCoinflipWatch .ModalContent .CoinflipBody .CoinflipStatus .Flipper .CoinFlip.Black-2 {
            background-image: url(../../../static/roll-blue.png); }
  .ModalCoinflipWatch .CoinflipFooterContent {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px; }
    .ModalCoinflipWatch .CoinflipFooterContent .FooterCoinflipInfo {
      display: flex;
      flex: 1;
      gap: 10px;
      justify-content: flex-start;
      padding: 10px 15px;
      flex-flow: column nowrap;
      overflow: auto; }
      .ModalCoinflipWatch .CoinflipFooterContent .FooterCoinflipInfo span {
        width: fit-content;
        text-align: left; }
    .ModalCoinflipWatch .CoinflipFooterContent button {
      display: flex;
      padding: 15px;
      justify-content: center;
      color: #f3f3ff99;
      background-color: transparent;
      font-weight: 600;
      text-transform: uppercase; }

@keyframes CoinflipRoll {
  100% {
    background-position: -39800px; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media (max-width: 750px) {
  .CoinflipStatus {
    transform: translateX(-50%) scale(0.8) !important; }
  .CoinflipUser {
    padding-top: 200px !important; } }
