@keyframes logoPulse {
  0% {
    filter: drop-shadow(0 0 0 #ffffff); }
  50% {
    filter: drop-shadow(0 0 2px #ffffff); }
  100% {
    filter: drop-shadow(0 0 0 #ffffff); } }

.App {
  position: fixed;
  background-color: #16181f;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .App .Content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    padding: 0 280px 0; }
    .App .Content main {
      max-height: 100vh;
      padding: 80px 50px;
      overflow-x: hidden;
      overflow-y: scroll; }

.HeadBar {
  position: fixed;
  top: 0;
  left: 250px;
  right: 250px;
  height: 80px;
  background-color: #1a1c24;
  z-index: 1000;
  display: flex; }
  .HeadBar .Toggler {
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 100%;
    color: #f3f3ff99;
    font-size: 1.25em;
    display: none; }
  .HeadBar .Logo {
    display: flex;
    align-items: center;
    height: 100%;
    margin: auto;
    cursor: pointer; }
    .HeadBar .Logo img {
      display: block;
      height: 78px;
      transition: transform .2s; }
    .HeadBar .Logo :hover {
      animation: logoPulse 2s ease;
      animation-iteration-count: infinite; }

.OutsideToggle {
  position: fixed;
  z-index: 1500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000069;
  display: none; }
  .OutsideToggle.show {
    display: block; }

@media (max-width: 1280px) {
  .App .Content {
    padding: 0; }
    .App .Content main {
      height: calc(100% - 70px);
      padding: 0;
      margin: 0 20px 70px;
      border-radius: 3px; }
  .Toggler {
    display: flex !important; }
  .HeadBar {
    left: 0;
    right: 0;
    top: auto;
    bottom: 0;
    height: 60px; } }
