.JackpotRound:nth-child(n+2) .DepositItems {
  transform: scale(0.55);
  height: 70px;
  margin-top: -68px; }

.JackpotRound:nth-child(n+2) .WinnerBox:before, .JackpotRound:nth-child(n+2) .DepositInfo:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  background-image: repeating-linear-gradient(135deg, #606dbc00, #606dbc00 10px, #00000020 10px, #00000020 22px);
  background-repeat: repeat; }

.Jackpot {
  width: 100%;
  margin: 50px auto 20px;
  max-width: 840px; }
  .Jackpot .JackpotPanel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 20px;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap; }
    .Jackpot .JackpotPanel .UserDepositInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-color: #323541;
      border-radius: 2px;
      flex: 1;
      max-width: 300px;
      margin: auto; }
      .Jackpot .JackpotPanel .UserDepositInfo span {
        padding: 10px 15px;
        color: #f3f3ff66; }
    .Jackpot .JackpotPanel button.Deposit {
      background-color: #00c73d;
      flex: 1;
      max-width: 300px;
      border-radius: 2px;
      padding: 10px 25px;
      color: #fff;
      margin: auto; }
  .Jackpot .JackpotCurrentGame {
    margin: 20px 0;
    border-radius: 2px;
    position: relative; }
  .Jackpot .JackpotStats {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.6);
    right: 0; }
    .Jackpot .JackpotStats .Stat {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      font-size: 1.15em;
      font-weight: 600;
      height: 100%;
      padding: 1.15rem 1rem;
      position: relative; }
      .Jackpot .JackpotStats .Stat span {
        padding: 0; }
      .Jackpot .JackpotStats .Stat i {
        margin-right: 10px; }
      .Jackpot .JackpotStats .Stat:first-child:after {
        position: absolute;
        right: 0;
        height: 70%;
        top: 15%;
        width: 1px;
        content: '';
        background-color: #ffffff0d; }
      .Jackpot .JackpotStats .Stat:last-child:after {
        position: absolute;
        left: 0;
        height: 70%;
        top: 15%;
        width: 1px;
        content: '';
        background-color: #ffffff0d; }
  .Jackpot .JackpotTimer {
    padding: 5px 15px;
    background-color: #1a1c24;
    border-radius: 2px;
    display: flex; }
    .Jackpot .JackpotTimer .Visualiser {
      display: flex;
      flex: 1;
      height: 3px;
      margin: auto;
      margin-right: 20px; }
      .Jackpot .JackpotTimer .Visualiser .Bar {
        background-color: #00c73d;
        border-radius: 2px;
        transition: width 1s ease; }
    .Jackpot .JackpotTimer .Counter {
      font-weight: 600; }
  .Jackpot .ProgressBox {
    position: relative;
    border-radius: 3px;
    transition: height .5s ease-out;
    height: 90px; }
    .Jackpot .ProgressBox .RollOverflow {
      overflow: hidden;
      border-radius: 3px;
      padding: 15px 0;
      height: 90px; }
      .Jackpot .ProgressBox .RollOverflow .Indicator {
        width: 2px;
        height: 100%;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
        animation: indicatorIn .25s ease-out; }
        .Jackpot .ProgressBox .RollOverflow .Indicator:before {
          content: "";
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 10px solid #fff;
          position: absolute;
          top: -5px;
          left: 50%;
          transform: translateX(-50%); }
        .Jackpot .ProgressBox .RollOverflow .Indicator:after {
          content: "";
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 10px solid #fff;
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%); }
      .Jackpot .ProgressBox .RollOverflow .RollBox {
        width: 100%;
        height: 60px;
        z-index: 1;
        border-radius: 3px;
        overflow: hidden;
        position: relative;
        background-color: #1a1c24;
        background-image: linear-gradient(135deg, #00000026 25%, #0000 25%, #0000 50%, #00000026 50%, #00000026 75%, #0000 75%, #0000);
        background-size: 1rem 1rem;
        animation: backgroundAnimation 0.5s linear infinite; }
        .Jackpot .ProgressBox .RollOverflow .RollBox .WinnerInfo {
          animation: slideIn 0.4s ease-in;
          display: flex;
          height: 100%;
          width: 100%;
          justify-content: center;
          align-items: center; }
        .Jackpot .ProgressBox .RollOverflow .RollBox .RollItems {
          flex: 0 0 100%;
          z-index: -1;
          display: flex;
          transform: translate(0%); }
          .Jackpot .ProgressBox .RollOverflow .RollBox .RollItems .RollItem {
            flex: 0 0 100%;
            z-index: -1;
            margin: 0; }
            .Jackpot .ProgressBox .RollOverflow .RollBox .RollItems .RollItem .PlayerItems {
              height: 60px;
              display: flex;
              transition: width 0.15s ease;
              width: 0;
              transition: width 0.25s ease;
              width: 100%;
              overflow: hidden;
              margin: inherit; }
              .Jackpot .ProgressBox .RollOverflow .RollBox .RollItems .RollItem .PlayerItems .PlayerItem {
                height: 100%;
                animation: fadeIn 0.15s ease;
                animation: backgroundAnimation 0.5s linear infinite;
                transition: width .25s ease;
                background-image: linear-gradient(135deg, #00000026 25%, #0000 25%, #0000 50%, #00000026 50%, #00000026 75%, #0000 75%, #0000);
                background-size: 1rem 1rem;
                background-color: #1a1c24; }
      .Jackpot .ProgressBox .RollOverflow:not(.Rolling) {
        animation: playerItemIn .25s ease; }
  .Jackpot .JackpotRounds {
    display: flex;
    flex-direction: column; }
    .Jackpot .JackpotRounds .JackpotRound {
      display: flex;
      flex-direction: column;
      animation: playerIn 0.25s ease;
      background-color: rgba(22, 22, 22, 0.8);
      margin-bottom: 30px;
      border-radius: 2px; }
      .Jackpot .JackpotRounds .JackpotRound a, .Jackpot .JackpotRounds .JackpotRound span, .Jackpot .JackpotRounds .JackpotRound img {
        z-index: 100; }
      .Jackpot .JackpotRounds .JackpotRound .RoundWinner {
        overflow: hidden;
        border-radius: 3px 3px 0 0; }
        .Jackpot .JackpotRounds .JackpotRound .RoundWinner .WinnerBox {
          padding: 15px 15px;
          max-width: 100%;
          animation: slideIn 0.4s ease-in;
          display: grid;
          align-items: center;
          justify-content: space-between;
          grid-template-columns: 1fr 40px;
          gap: 10px;
          transform: translate3d(0, 0, 0);
          border-radius: 3px 3px 0 0;
          background: #FCA800;
          color: black; }
          .Jackpot .JackpotRounds .JackpotRound .RoundWinner .WinnerBox .Info {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 5px; }
            .Jackpot .JackpotRounds .JackpotRound .RoundWinner .WinnerBox .Info img {
              width: 34px;
              border-radius: 34px;
              border: 2px solid;
              display: block;
              margin-right: 15px; }
            .Jackpot .JackpotRounds .JackpotRound .RoundWinner .WinnerBox .Info .WinnerText {
              color: #fff;
              font-size: 14px; }
              .Jackpot .JackpotRounds .JackpotRound .RoundWinner .WinnerBox .Info .WinnerText span {
                font-weight: 600;
                margin: 0 5px; }
          .Jackpot .JackpotRounds .JackpotRound .RoundWinner .WinnerBox a {
            text-align: center;
            color: #1da1f2; }
      .Jackpot .JackpotRounds .JackpotRound .RoundDeposits {
        display: inline-flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        transition: opacity .5s ease-out, height .5s ease-out;
        padding: 15px 0; }
        .Jackpot .JackpotRounds .JackpotRound .RoundDeposits .NoDeposits {
          text-align: center;
          padding: 15px;
          width: 100%; }
          .Jackpot .JackpotRounds .JackpotRound .RoundDeposits .NoDeposits i {
            margin-right: 7px; }
        .Jackpot .JackpotRounds .JackpotRound .RoundDeposits .RoundDeposit {
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 3px;
          padding: 0 15px;
          overflow: hidden;
          width: 100% !important; }
          .Jackpot .JackpotRounds .JackpotRound .RoundDeposits .RoundDeposit .DepositInfo {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 100%; }
            .Jackpot .JackpotRounds .JackpotRound .RoundDeposits .RoundDeposit .DepositInfo img {
              width: 34px;
              height: 34px;
              border-radius: 30px;
              margin: 10px;
              border: 2px solid;
              display: block; }
            .Jackpot .JackpotRounds .JackpotRound .RoundDeposits .RoundDeposit .DepositInfo .DepositName, .Jackpot .JackpotRounds .JackpotRound .RoundDeposits .RoundDeposit .DepositInfo .DepositChances {
              overflow: hidden;
              margin-left: 5px;
              white-space: nowrap;
              text-overflow: ellipsis;
              color: #f3f3ff99;
              font-weight: 500; }
            .Jackpot .JackpotRounds .JackpotRound .RoundDeposits .RoundDeposit .DepositInfo .DepositChances {
              font-weight: 400;
              margin-left: 15px; }
            .Jackpot .JackpotRounds .JackpotRound .RoundDeposits .RoundDeposit .DepositInfo .DepositValue {
              margin-left: auto;
              font-weight: 600;
              color: #f3f3ff99; }
          .Jackpot .JackpotRounds .JackpotRound .RoundDeposits .RoundDeposit .DepositItems {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 5px; }
            .Jackpot .JackpotRounds .JackpotRound .RoundDeposits .RoundDeposit .DepositItems img {
              max-width: 40px;
              margin: 5px;
              cursor: pointer; }
        .Jackpot .JackpotRounds .JackpotRound .RoundDeposits.Ended {
          display: flex;
          justify-content: center;
          padding: 20px 15px;
          background-color: #1a1c24;
          flex-direction: row-reverse; }
          .Jackpot .JackpotRounds .JackpotRound .RoundDeposits.Ended .RoundDeposit {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            border: none;
            border-bottom: 3px solid;
            margin-right: 1px;
            cursor: pointer; }
            .Jackpot .JackpotRounds .JackpotRound .RoundDeposits.Ended .RoundDeposit img, .Jackpot .JackpotRounds .JackpotRound .RoundDeposits.Ended .RoundDeposit .DepositName, .Jackpot .JackpotRounds .JackpotRound .RoundDeposits.Ended .RoundDeposit i, .Jackpot .JackpotRounds .JackpotRound .RoundDeposits.Ended .RoundDeposit .DepositItems, .Jackpot .JackpotRounds .JackpotRound .RoundDeposits.Ended .RoundDeposit .DepositValue, .Jackpot .JackpotRounds .JackpotRound .RoundDeposits.Ended .RoundDeposit .DepositChances {
              display: none; }
      .Jackpot .JackpotRounds .JackpotRound .RoundFooter {
        padding: 20px 15px;
        border-radius: 2px;
        font-size: 11px;
        transition: all 0.25s ease;
        animation: fadeIn 0.25s ease;
        background-color: #1a1c24; }
        .Jackpot .JackpotRounds .JackpotRound .RoundFooter span.Info {
          font-weight: 600; }
        .Jackpot .JackpotRounds .JackpotRound .RoundFooter .SimpleInfo {
          display: grid;
          grid-template-columns: auto auto;
          gap: 20px;
          justify-content: space-between;
          align-items: center; }
          .Jackpot .JackpotRounds .JackpotRound .RoundFooter .SimpleInfo span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis; }
          .Jackpot .JackpotRounds .JackpotRound .RoundFooter .SimpleInfo .Extender {
            cursor: pointer; }
        .Jackpot .JackpotRounds .JackpotRound .RoundFooter .ExtendedInfo {
          max-height: 0;
          overflow: hidden;
          opacity: 0;
          transition: all 0.15s ease;
          padding: 0 10px; }
        .Jackpot .JackpotRounds .JackpotRound .RoundFooter.Active .ExtendedInfo {
          max-height: 100vh;
          opacity: 1;
          padding: 10px; }

.itemPopup {
  background-color: #1a1c24 !important;
  border-color: transparent !important;
  border-radius: 2px !important;
  box-shadow: none !important;
  color: #f3f3ff99 !important;
  backdrop-filter: blur(4px);
  padding: 0 !important; }
  .itemPopup::before {
    background-color: #1a1c24 !important;
    box-shadow: 1px 1px 0 0 transparent !important;
    backdrop-filter: blur(4px); }
  .itemPopup span {
    position: relative;
    padding: 10px;
    display: block; }
    .itemPopup span .userColor {
      height: 2px;
      width: 100%;
      border-radius: 2px;
      padding: 0; }
    .itemPopup span .name {
      max-width: 80px;
      word-wrap: break-word;
      padding-bottom: 3px; }
    .itemPopup span .price {
      position: absolute;
      top: 0;
      left: 0;
      padding: 5px;
      background-color: #16171c;
      border-radius: 2px 0 2px 0; }
  .itemPopup img {
    max-height: 80px;
    max-width: 100%;
    margin: 10px;
    transition: all .15s ease;
    display: block; }

@keyframes playerIn {
  0% {
    transform: scale(0);
    opacity: 0; }
  100% {
    transform: scale(1); } }

@keyframes slideIn {
  0% {
    transform: translate3d(0, 200%, 0); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes playerItemIn {
  0% {
    width: 0; } }

@keyframes backgroundAnimation {
  0% {
    background-position: -1rem 0; } }

@keyframes indicatorIn {
  0% {
    opacity: 0; } }
